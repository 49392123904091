import React, { useEffect, useState } from 'react';
import Offer from '../../component/Sales/Offer';
import SpinnerLoading from '../../component/Loading/SpinnerLoading';
import { useFetch } from '../../hooks/useFetch';
import { useTranslation } from 'react-i18next';
import PackageLoading from '../../component/Loading/PackageLoading';
import API_ENDPOINTS from '../../api';

const OfferPage = () => {
  const { t,i18n } = useTranslation(); // Initialize translation
  const { data: Sale, loading: offerLoading, error: offerError } = useFetch(API_ENDPOINTS.OFFERS.GET_ALL);
  const { data: packagesData, loading: packageLoading, error: packageError } = useFetch(API_ENDPOINTS.PACKAGES.GET_ALL);

  const [offers, setOffers] = useState([]);
  const [packageSale, setPackageSale] = useState([]);
  const [activeTab, setActiveTab] = useState('specialOffer'); // Control active tab state
  

  // Fetch special offers and packages on sale
  useEffect(() => {
    if (Sale) { 
      
      setOffers(Sale.sales);
    }

    if (packagesData) {
      setPackageSale(packagesData.packages.filter(pkg => pkg.onSale === true));
    }
  }, [Sale, packagesData]);

  // Sort offers based on expiration status
  const sortedOffers = offers.slice().sort((a, b) => {
    const isExpiredA = new Date(a.endDate) < new Date();
    const isExpiredB = new Date(b.endDate) < new Date();
    return isExpiredA - isExpiredB; // Non-expired offers come first
  });

  // Handle loading and error states
  if (offerError || packageError) {
    return <div className='h-[80vh]'>{offerError?.message || packageError?.message}</div>;
  }

  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <header className="text-center py-4">
        <h1 className="text-4xl font-bold text-primeColor mb-2 after:block after:w-[150px] after:h-1 after:bg-secondColor after:mx-auto after:mt-2">
          {t('offer.salesPackage.header')} {/* Translated Header */}
        </h1>
        <h2 className="text-xl text-gray-600 mt-2">
          {t('offer.salesPackage.description')} {/* Translated Subheader */}
        </h2>
      </header>

      {/* Tab Menu */}
<div className="flex justify-center mb-8">
  <button
    className={`px-8 py-2 text-lg font-semibold transition-all duration-300 
                ${i18n.language === "ar" ? "rounded-r-lg" : "rounded-l-lg"} 
                ${activeTab === 'specialOffer' 
                  ? 'bg-secondColor text-white shadow-lg' 
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300 hover:text-gray-900'} 
                focus:outline-none focus:ring-2 focus:ring-primeColor`}
    onClick={() => setActiveTab('specialOffer')}
  >
    {t('offer.specialOffer')} {/* Special Offer Tab */}
  </button>
  
  <button
    className={`px-8 py-2 text-lg font-semibold transition-all duration-300 
                ${i18n.language === "ar" ? "rounded-l-lg" : "rounded-r-lg"} 
                ${activeTab === 'packagesOffer' 
                  ? 'bg-secondColor text-white shadow-lg' 
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300 hover:text-gray-900'} 
                focus:outline-none focus:ring-2 focus:ring-primeColor`}
    onClick={() => setActiveTab('packagesOffer')}
  >
    {t('offer.packageOffer')} {/* Packages' Offer Tab */}
  </button>
</div>


      {/* Content based on active tab */}
      <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {(offerLoading || packageLoading) ? (
          // Render loading skeletons while data is loading
          Array.from({ length: 6 }).map((_, index) => (
            <PackageLoading key={index} /> // Render PackageLoading component for each placeholder
          ))
        ) : activeTab === 'specialOffer' ? (
          // Render special offers when "Special Offer" tab is active
          sortedOffers.length > 0 ? (
            sortedOffers.map((offer) => (
              <Offer key={offer._id} specialOffer={true} packageData={offer} />
            ))
          ) : (
            <div>{t('offer.packages.noOffers')}</div> // Display when no special offers available
          )
        ) : activeTab === 'packagesOffer' ? (
          // Render package offers when "Packages' Offer" tab is active
          packageSale.length > 0 ? (
            packageSale.map((pkg) => (
              <Offer key={pkg._id} packageSale={true}  packageData={pkg} />
            ))
          ) : (
            <div>{t('offer.packages.noPackages')}</div> // Display when no package offers available
          )
        ) : null}
      </div>
    </div>
  );
};

export default OfferPage;
