// Packages.js
import React, { useRef, useState, useEffect } from 'react';
import CardPackage from '../../component/Package/CardPackage';
import { FaArrowLeft, FaArrowRight, FaBox, FaTags } from 'react-icons/fa';
import './Packages.scss';
import { Link } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import SpinnerLoading from '../../component/Loading/SpinnerLoading';
import { useTranslation } from 'react-i18next';
import PackageLoading from '../../component/Loading/PackageLoading';
import API_ENDPOINTS from '../../api';

const Packages = () => { 
  const { t , i18n } = useTranslation(); // Use the translation hook
   

  // Fetch categories and packages data
  const { data: packagesData, loading: packageLoading, error: packageError } = useFetch(API_ENDPOINTS.PACKAGES.GET_ALL);
  const { data: categoriesData, loading, error } = useFetch(API_ENDPOINTS.Category.GET_ALL);

  const [activeCategoryId, setActiveCategoryId] = useState(null); // Active category ID

  useEffect(() => {
    if (categoriesData && categoriesData.categories.length > 0) {
      setActiveCategoryId(categoriesData.categories[0]._id); // Set first category as active
    }
  }, [categoriesData]);

  // Function to get packages related to the active category
  const getActivePackageData = () => {
    return packagesData && packagesData.packages
      ? packagesData.packages.filter(pkg => pkg.categoryId._id === activeCategoryId)
      : [];
  };

  // Scroll handling logic for navigation
  const navRef = useRef(null);
  useEffect(() => {
    if (navRef.current) {
      const activeButton = navRef.current.querySelector(`[data-id="${activeCategoryId}"]`);
      if (activeButton) {
        const { offsetLeft, offsetWidth } = activeButton;
        const navWidth = navRef.current.offsetWidth;
        const scrollPos = offsetLeft - (navWidth - offsetWidth) / 2;
        navRef.current.scrollTo({ left: scrollPos, behavior: 'smooth' });
      }
    }
  }, [activeCategoryId]);

  // Handlers for next and previous navigation
  const goToNext = () => {
    const currentIndex = categoriesData.categories.findIndex(category => category._id === activeCategoryId);
    if (currentIndex < categoriesData.categories.length - 1) {
      setActiveCategoryId(categoriesData.categories[currentIndex + 1]._id);
    }
  };

  const goToPrevious = () => {
    const currentIndex = categoriesData.categories.findIndex(category => category._id === activeCategoryId);
    if (currentIndex > 0) {
      setActiveCategoryId(categoriesData.categories[currentIndex - 1]._id);
    }
  };


  
  if ( loading) return <div className='h-[80vh]'><SpinnerLoading/></div>;
  if (error || packageError) return <div className='h-[80vh]'>{error.message}</div>;

  const activePackageData = getActivePackageData();

  return (
    <div className="min-h-screen package bg-gray-100 p-4" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
      {/* Header */}
      <header className="relative flex flex-col justify-center items-center py-4">
  <div className="flex-1 text-center">
    <h1 className="text-4xl font-bold text-primeColor mb-2 after:block after:w-[150px] after:h-1 after:bg-secondColor after:mx-auto after:mt-2">
      {t('packages.title')}
    </h1>
    <h2 className="text-lg text-gray-600">{t('packages.description')}</h2>
  </div>

  {/* Animated Button under the title and description */}
  <div className={`flex justify-center items-center ${i18n.language === 'ar' ? 'mt-4' : 'mt-4'}`}>
    <Link
      to="/offer"
      className={`flex justify-center gap-[5px] items-center relative group inline-block px-4 sm:px-6 md:px-8 py-3 sm:py-3 md:py-4 bg-gradient-to-r from-[#ff7f50] to-[#ff4500] text-white font-semibold rounded-full shadow-lg hover:shadow-2xl transform transition-all duration-500 ease-in-out hover:scale-110`}
    >
      <FaTags />
      <span className="ml-8">{t('packages.offer')}</span>
    </Link>
  </div>
</header>

{/* Navigation Bar */}
<div className="relative package_nav flex items-center w-full mt-6 px-3 mb-10">
  {/* Left Arrow */}
  <button
    onClick={goToPrevious}
    className="absolute left-0 z-10 p-3 text-primeColor bg-gray-200 hover:bg-gray-300 rounded-full shadow-md transition duration-300"
  >
    <FaArrowLeft size={28} />
  </button>

  {/* Navigation Bar */}
  <nav
    ref={navRef}
    className="flex justify-start w-[80%] gap-4 overflow-x-auto scrollbar-hide p-2 px-3 w-full border border-gray-200 rounded-lg shadow-sm bg-white"
    style={{ transition: 'width 0.3s ease' }}
  >
    {categoriesData.categories.map(category => (
      <button
        key={category._id}
        data-id={category._id}
        onClick={() => setActiveCategoryId(category._id)}
        className={`flex items-center gap-2 justify-center px-4 py-3 rounded-lg transition-all duration-300 ${
          activeCategoryId === category._id
            ? 'bg-gradient-to-r from-primeColor to-secondColor text-white shadow-md'
            : 'text-gray-600 hover:bg-backgroundColor2 hover:text-gray-800 hover:shadow-lg'
        }`}
        style={{ maxWidth: 'content-fit', fontSize: '16px' }}
      >
        <FaBox className="mr-2" style={{ width: '24px', height: '24px' }} />
        
        {/* Text Container */}
        <span
          className="flex-1 text-end overflow-hidden whitespace-nowrap text-ellipsis"
          style={{ maxWidth: '100%', paddingRight: '10px' }}
        >
          {i18n.language === 'ar' ? category.title_ar : category.title_en}
        </span>
      </button>
    ))}
  </nav>

  {/* Right Arrow */}
  <button
    onClick={goToNext}
    className="absolute right-0 z-10 p-3 text-primeColor bg-gray-200 hover:bg-gray-300 rounded-full shadow-md transition duration-300"
  >
    <FaArrowRight size={28} />
  </button>
</div>



      {/* Package Cards */}
      <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {packageLoading ? (
        // Render loading skeletons
        Array.from({ length: 6 }).map((_, index) => (
          <PackageLoading key={index} />
        ))
      ) : activePackageData.length === 0 ? (
        <div className="col-span-3 text-center text-gray-600">
          <h3>{t('packages.noPackages')}</h3>
        </div>
      ) : (
        activePackageData.map((packageData) => (
          <CardPackage key={packageData._id} packageData={packageData} />
        ))
      )}
    </div>
    </div>
  );
};

export default Packages;