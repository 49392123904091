import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt, FaCheckCircle, FaClock, FaPercent, FaTag, FaVial } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

const CardPackage = ({ packageData }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const time = packageData.endDate && packageData.StartDate
  const packageSale = packageData?.onSale;
  const now = new Date();
  const end = new Date(packageData.endDate);
  const timeDiff = end - now;
  const formattedStartDate = packageData.startDate ? moment(packageData.startDate).format('DD MMM YYYY') : null;
  const formattedEndDate = packageData.endDate ? moment(packageData.endDate).format('DD MMM YYYY') : null;

  const isExpired = end && end < now;

  // Handle booking navigation
  const handleBooking = () => {
    navigate('/package/booking', { state: packageData });
  };

  // Calculate days left for the sale
  const calculateTimeLeft = () => {
    if (timeDiff <= 0) return t('details.expired');
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24))+1;
    return `${days} ${t('days')}`;
  };

  // Optimized function to handle expired offers
  const handleExpiredOffers = useCallback(async () => {
    if (packageSale && isExpired) {
      try {
        await axios.patch(`http://localhost:4200/api/v1/package/find/${packageData._id}`, {
          onSale: false,
        });
      } catch (error) {
        // console.error('Error updating package sale status:', error);
      }
    }
  }, [packageSale, isExpired, packageData._id]);

  useEffect(() => {
    if (isExpired && time) {
      handleExpiredOffers();
    }
  }, [handleExpiredOffers]);

  return (
    <div className="bg-white cardpackage shadow-lg rounded-[20px] overflow-hidden hover:shadow-2xl transform transition-transform duration-300 flex flex-col relative group">
      {/* Image Section */}
      {packageData.image && (
        <div
          className="relative w-full h-46 pb-[70%] overflow-hidden group"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <img
            src={packageData.image}
            alt={i18n.language === 'ar' ? packageData.name_ar : packageData.name_en}
            className="absolute inset-0 w-full h-full object-fill brightness-[80%]  transition-transform duration-300 transform hover:scale-105"
          />

          {/* On Sale Badge */}
          {packageSale && (
            <div
              className={`absolute top-2 z-50 left-2 bg-gradient-to-r from-red-500 to-red-700 
                text-white text-sm font-semibold flex items-center gap-2 px-3 py-2 rounded-md shadow-lg
                transform transition duration-300 ${isHovered ? 'scale-105' : ''}`}
            >
              <FaTag className="w-4 h-4" />
              {t('packages.onSale')}
            </div>
          )}

{/* Sale Information Overlay */}
{packageSale && isHovered && (
  <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-800 to-backgroundColor2 bg-opacity-60 backdrop-blur-md flex flex-col justify-center items-center p-8 shadow-2xl rounded-xl transition-transform duration-500 ease-in-out transform hover:scale-105">

    {/* Sale Price */}
    {packageData.priceSale && (
      <div className="flex flex-col items-center mb-6">
        <div className="text-yellow-400 text-2xl font-extrabold flex items-center mb-2">
          <FaTag className="mr-2 h-7 w-7 text-yellow-500 animate-pulse" />
          {t('packages.salePrice')}:
          <span className="ml-2 text-yellow-300">
            {packageData.priceSale}{t('currency')}
          </span>
        </div>
        <div className="h-1 w-16 bg-yellow-300 rounded-full"></div>
      </div>
    )}

    {/* Sale Percent Discount */}
    {packageData.salePercent && (
      <div className="flex flex-col items-center mb-6">
        <div className="text-green-400 text-xl font-semibold flex items-center mb-2">
          <FaPercent className="mr-2 h-6 w-6 text-green-500 animate-bounce" />
          {t('details.discount')}:
          <span className="ml-2 text-green-300">
            {packageData.salePercent}%
          </span>
        </div>
        <div className="h-1 w-12 bg-green-400 rounded-full"></div>
      </div>
    )}

    {/* Sale Duration */}
    {packageData.duration && (
      <div className="flex flex-col items-center mb-6">
        <div className="text-red-400 text-lg font-semibold flex items-center">
          <FaCalendarAlt className="mr-2 h-5 w-5 text-red-400 animate-spin-slow" />
          {t('packages.saleDuration')}:
          <span className="ml-2 text-red-300">
            {packageData.duration} {t('days')}
          </span>
        </div>
        <div className="h-1 w-10 bg-red-400 rounded-full"></div>
      </div>
    )}

    {/* Sale Time Left */}
    {calculateTimeLeft() && time && (
      <div className="flex flex-col items-center mb-6">
        <div className="text-blue-400 text-md flex items-center">
          <FaClock className="mr-2 h-4 w-4 text-blue-400 animate-pulse" />
          {t('packages.saleTimeLeft')}:
          <span className="ml-1 text-blue-300">
            {calculateTimeLeft()}
          </span>
        </div>
        <div className="h-1 w-8 bg-blue-400 rounded-full"></div>
      </div>
    )}

    {/* Test Sale */}
    {packageData.testsSale && (
      <div className="flex flex-col items-center">
        <div className="text-blue-500 text-sm flex items-center">
          <FaCheckCircle className="mr-2 h-5 w-5 text-blue-500 animate-bounce" />
          {t('details.tests')}:
          <span className="ml-1 text-blue-300">
            {packageData.testsSale}
          </span>
        </div>
        <div className="h-1 w-8 bg-blue-400 rounded-full"></div>
      </div>
    )}
  </div>
)}


        </div>
      )}

      {/* Package Info Section */}
      <div className="flex-grow p-4 flex flex-col gap-[15px] justify-between">
        <h2 className="text-xl font-bold mb-2 text-secondColor">
          {i18n.language === 'ar' ? packageData.name_ar : packageData.name_en}
        </h2>

        <div className="py-[10px] flex items-center gap-x-2 text-gray-600 bg-gray-100 p-2 rounded-lg">
          <FaVial className="text-lg text-primeColor" />
          <span className="text-sm font-semibold">
            {t('packages.test')}: {packageData.tests}
          </span>
        </div>

        <div className="flex justify-center gap-4">
          <button
            onClick={handleBooking}
            className="bg-secondColor text-white py-2 px-4 rounded transition-all duration-500 shadow-lg hover:bg-secondColor/90 focus:outline-none focus:ring-2 focus:ring-secondColor"
          >
            {t('packages.bookNow')}
          </button>
          <Link
            to="/package/detail"
            state={packageData}
            className="bg-primeColor text-white py-2 px-4 rounded transition-all duration-500 shadow-lg hover:bg-primeColor/90 focus:outline-none focus:ring-2 focus:ring-primeColor"
          >
            {t('packages.more')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardPackage;
