import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import partner1 from "../../images/logo/شركة ابوظبي الوطنية للتأمين.png";
import partner2 from "../../images/logo/mednet.png";
import partner3 from "../../images/logo/Al-Madallah.png";
import partner4 from "../../images/logo/nas_1.png";
import partner5 from "../../images/logo/1685339923_8158f4811f7b5dd3d725.png";
import { useFetch } from "../../hooks/useFetch";
import SpinnerLoading from "../../component/Loading/SpinnerLoading";
import location from "../../images/Landing/location.png";
import land1 from "../../images/Landing/land1.jpg";
import info from "../../images/Landing/info.png";
import { useTranslation } from "react-i18next";
import SalesPackageSection from "./SalesPackageSection";
import PartnerLogos from "./PartnerLogos";
import SlideContent from "./SlideContent";
import i18n from "../../i18n";
import API_ENDPOINTS from "../../api";

// Partner logos
const partnerLogos = [partner1, partner2, partner3, partner4, partner5];

const LandingPage = () => {
  const { data: Sale, loading, error } = useFetch(API_ENDPOINTS.OFFERS.GET_ALL);
  const [index, setIndex] = useState(0);
  const [packageIndex, setPackageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const slides = [
    {
      id: 1,
      header: t("home.landingPage.slides.0.header"),
      content: t("home.landingPage.slides.0.content"),
      image: location,
      labelBtn: t("home.landingPage.slides.0.labelBtn"),
      path: "/location",
    },
    {
      id: 2,
      header: t("home.landingPage.slides.1.header"),
      content: t("home.landingPage.slides.1.content"),
      image: land1,
      labelBtn: t("home.landingPage.slides.1.labelBtn"),
      path: "/information",
    },
    {
      id: 3,
      header: t("home.landingPage.slides.2.header"),
      content: t("home.landingPage.slides.2.content"),
      image: info,
      labelBtn: t("home.landingPage.slides.2.labelBtn"),
      path: "/package",
    },
  ];
   // Filter sales to exclude expired ones
   const validSales = Sale?.sales.filter((sale) => {
    const endDate = new Date(sale.endDate);
    return endDate > new Date();
  });

  const currentPackage = validSales && validSales[packageIndex];
  // console.log(currentPackage);
  // console.log(validSales);
  
  

  // const currentPackage = Sale && Sale.sales[packageIndex];

  // Auto-play effect for slides and packages
  useEffect(() => {
    const slideTimer = setInterval(() => changeSlide(1), 6000);
    const packageTimer = setInterval(() => {
      if (validSales) {
        setPackageIndex((prevIndex) => (prevIndex + 1) % validSales.length);
      }
    }, 4000);

    return () => {
      clearInterval(slideTimer);
      clearInterval(packageTimer);
    };
  }, [validSales]);

  const changeSlide = (direction) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setIndex((prevIndex) => (prevIndex + direction + slides.length) % slides.length);
      setIsTransitioning(false);
    }, 200);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") changeSlide(-1);
      if (e.key === "ArrowRight") changeSlide(1);
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  const handleButtonClick = () => {
    const currentSlide = slides[index];
    navigate(currentSlide.path);
  };

  if (loading) return <div className="h-[80vh]"><SpinnerLoading /></div>;
  if (error) return <div className="h-[80vh]">{error.message}</div>;

  return (
    <div className="font-arabic flex flex-col items-center justify-center h-screen bg-backgroundColor relative">
      <div className="relative flex flex-col items-center justify-center w-full h-full bg-white shadow-md p-0">
        {/* Main slide section */}
        <div
          className={`relative w-full h-screen bg-backgroundColor2 transition-opacity duration-500 ${
            isTransitioning ? "opacity-0" : "opacity-100"
          }`}
        >
          <div
            className="relative w-full h-screen bg-cover"
            style={{ backgroundImage: `url(${slides[index].image})` }}
          >
            {/* Dark overlay to reduce brightness */}
            <div className="absolute inset-0 bg-white opacity-60"></div>

            {/* Hero and sales package layout */}
            <div className="absolute inset-0 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 items-center justify-between px-6 md:px-16 lg:px-32 mt-[40px]">
              {/* Show SlideContent only on medium and larger screens */}
              <div className="hidden lg:block col-span-2">
                <SlideContent slide={slides[index]} onClick={handleButtonClick} i18n={i18n} />
              </div>

              {/* Render SalesPackageSection only if Sale data is available */}
              {currentPackage && validSales.length > 0 && (
                <SalesPackageSection currentPackage={currentPackage} t={t} i18n={i18n} />
              )}
            </div>

            <PartnerLogos partnerLogos={partnerLogos} t={t} />
          </div>
        </div>

        {/* Navigation arrows */}
        <div
          className="hidden md:flex absolute left-4 top-1/2 transform -translate-y-1/2 cursor-pointer text-4xl text-primeColor hover:text-secondColor transition-transform duration-300 transform hover:scale-110"
          onClick={() => changeSlide(-1)}
        >
          <FaChevronLeft />
        </div>
        <div
          className="hidden md:flex absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer text-4xl text-primeColor hover:text-secondColor transition-transform duration-300 transform hover:scale-110"
          onClick={() => changeSlide(1)}
        >
          <FaChevronRight />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
