import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import { FaArrowDown } from 'react-icons/fa';
import { useFetch } from '../../hooks/useFetch';
import CardPackage from '../Package/CardPackage'; // Import CardPackage
import { t } from 'i18next';
import API_ENDPOINTS from '../../api';

// Function to get random packages
const getRandomPackages = (arr, num) => {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
};
 
const RandomPackage = () => {
  const { data: packages, loading, error } = useFetch(API_ENDPOINTS.PACKAGES.GET_ALL);
  const randomPackages = getRandomPackages(packages ? packages.packages : [], 3);
  // console.log(randomPackages);
  
  return (
    <div className="p-6 bg-white">
      <header className="text-center py-4">
        <h1 className="text-4xl font-bold text-primeColor mb-2 after:block after:w-24 after:h-1 after:bg-secondColor after:mx-auto after:mt-2">
          {t("packages.title")}
        </h1>
        <h2 className="text-lg text-gray-500 mb-4">
        {t("packages.description")}
        </h2>
      </header>

      {/* Card Section */}
      <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {loading ? (
          <p>Loading packages...</p>
        ) : randomPackages.length > 0 ? (
          randomPackages.map((pkg, index) => (
            <CardPackage key={pkg._id} packageData={pkg} /> // Pass packageData directly
          ))
        ) : (
          <p>{t("packages.noPackages")}</p>
        )}
      </div>

      {/* Link to navigate to the package page */}
      <div className="flex justify-center animate-bounce underline mt-6">
        <Link 
          to="/package" 
          className="text-secondColor font-bold text-[16px] w-fit hover:text-thirdColor transition duration-300 hover:translate-x-1 font-huge flex items-center justify-center"
        >
          <span className="mr-2">{t("packages.home.more")}</span>
          <FaArrowDown className="transition-transform duration-300 transform hover:translate-x-1" />
        </Link>
      </div>
    </div>
  );
};

export default RandomPackage;
