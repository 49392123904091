import React, { useCallback, useEffect, useState } from 'react';
import { FaClock, FaTag, FaFire, FaCalendarAlt, FaVial, FaHourglassHalf, FaPercentage } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import moment from 'moment';
import API_ENDPOINTS from '../../api';

const Offer = ({ packageData, specialOffer, packageSale }) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [index, setIndex] = useState(0);


  const name = isArabic ? packageData.name_ar : packageData.name_en;
  const id = packageData._id;
  const salePrice = packageSale ? packageData.priceSale : specialOffer ? packageData.salePrice : null;
  const salePercent = packageSale ? packageData.salePercent : specialOffer ? packageData.salePercent : null;
  // console.log("salePercent" , salePercent);
  // console.log("specialOffer" , packageData);
  
  const offerDuration = specialOffer ? packageData.offerDuration : null;
  const originalPrice = packageSale ? packageData.price : null;
  const imagePackage =  packageData.image 
  const imageSale = Array.isArray(
    specialOffer && isArabic ? packageData.image_ar : packageData.image_en
  ) ? (specialOffer && isArabic ? packageData.image_ar : packageData.image_en) : [];
  
  const tests = specialOffer ? packageData.tests : packageSale ? packageData.testsSale || packageData.tests : null;
  const startDate = packageData.startDate;
  const endDate = packageData.endDate;
  const today = new Date();
  const isTime = startDate && endDate;
  const Duration = endDate ? Math.ceil((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)) : null;
  const TimeLeft = endDate ? Math.ceil((new Date(endDate) - today) / (1000 * 60 * 60 * 24)) : null;
  const isExpired = endDate && new Date(endDate) < today;
  const hasPriceInfo = salePrice || originalPrice || salePercent;
  const formattedStartDate = packageData.startDate ? moment(packageData.startDate).format(isArabic?'YYYY  MMM  DD':"DD MMM YYYY") : null;
  const formattedEndDate = packageData.endDate ? moment(packageData.endDate).format(isArabic?'YYYY MMM  DD':"DD MMM YYYY") : null;
  const changeSlide = (direction) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setIndex((prevIndex) => (prevIndex + direction + imageSale.length) % imageSale.length);
      setIsTransitioning(false);
    }, 200);
  };
  
  
  const deleteExpiredOffers = useCallback(async () => {
    try {
      // if (specialOffer && isExpired && isTime) {
      //   await axios.delete(API_ENDPOINTS.OFFERS.DELETE(id));
      // }
      if (packageSale && isExpired && isTime) {
        await axios.patch(API_ENDPOINTS.OFFERS.UPDATE(id), { onSale: false });
      }
    } catch (error) {
      console.error('Error deleting expired offers:', error);
    }
  }, [id, isExpired, specialOffer, packageSale]);

  // Auto-play effect for slides and packages
  useEffect(() => {
    const slideTimer = setInterval(() => changeSlide(1), 6000);
    
    
    return () => {
      clearInterval(slideTimer);
    };
  }, [index]);
  useEffect(() => {
    if (isExpired && isTime) {
      deleteExpiredOffers();
    }
  }, [isExpired, isTime, deleteExpiredOffers]);

  return (
    <div className="flex flex-col items-center max-w-xs md:max-w-sm lg:max-w-md bg-white rounded-[20px] shadow-lg overflow-hidden relative transition-transform duration-300 ease-in-out transform hover:shadow-xl hover:scale-105">
      {specialOffer && isExpired && (
      <div className="absolute z-[999] inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <span className="text-white text-2xl md:text-3xl lg:text-4xl font-bold animate-ZoomOut">Expired</span>
      </div>
    )}
      
      {/* Special Offer Badge */}
      {(specialOffer && salePercent) ? (
        <div className="absolute z-[998] top-4 left-4 bg-gradient-to-r from-yellow-500 to-orange-400 text-white px-3 py-1 text-xs font-bold rounded-full shadow-md flex items-center gap-x-2">
          <FaFire className="text-lg" />
          <span>{t('offer.salesPackage.discountTag')} {salePercent}%</span>
        </div>
      ):
      (
        <div className="absolute z-[998] top-4 left-4 bg-gradient-to-r from-yellow-500 to-orange-400 text-white px-3 py-1 text-xs font-bold rounded-full shadow-md flex items-center gap-x-2">
          <FaFire className="text-lg" />
          <span>{t('offer.salesPackage.discountTag')}</span>
        </div>
      )
      }

      {/* Package Sale Badge */}
      {packageSale && salePercent && (
        <div className="absolute z-[998] top-4 right-4 bg-gradient-to-r from-blue-600 to-blue-400 text-white px-3 py-1 text-xs font-bold rounded-full shadow-md flex items-center gap-x-2">
          <FaTag className="text-lg" />
          <span>{t('offer.salesPackage.discountTag')} {salePercent}%</span>
        </div>
      )}

      {/* Duration Badge */}
      {(specialOffer && offerDuration) || (packageSale && Duration) ? (
        <div className={`absolute z-[998] ${!salePercent ? 'top-4 left-4' : 'top-16 left-4'} bg-gradient-to-r from-green-500 to-green-400 text-white px-3 py-1 text-xs font-bold rounded-full shadow-md flex items-center gap-x-2`}>
          <FaClock className="text-lg" />
          <span>{specialOffer ? offerDuration : `${Duration} Days`}</span>
        </div>
      ) : null}

      {/* Time Left Badge */}
      {TimeLeft > 0 && (
        <div className={`absolute z-[998] ${!salePercent ? 'top-16 left-4' : 'top-16 right-4'} bg-gradient-to-r from-red-500 to-red-400 text-white px-3 py-1 text-xs font-bold rounded-full shadow-md flex items-center gap-x-2`}>
          <FaHourglassHalf className="text-lg" />
          <span>Left: {TimeLeft} Days</span>
        </div>
      )}


      {/* Package Image */}
      {/* Package Image */}
        {imagePackage && (
      <div className="w-full h-50 overflow-hidden">
          <img
            src={imagePackage}
            alt={name}
            className="w-full h-full object-fill transition-opacity duration-300 hover:opacity-90"
          />
      </div>
        )}
      {/* Sale Image */}
      {imageSale.length>0 && (
        <div className="w-full h-50 overflow-hidden relative">
          <img
            src={imageSale[index]}
            alt={name}
            className="w-full h-full object-fill transition-opacity duration-300 hover:opacity-90"
          />

        </div>
      )}


      {/* Package Info with flex-grow */}
      <div className="p-4 w-full grid gap-2 grid-cols-2 flex-grow">
        <h3 className="text-lg md:text-xl font-bold truncate text-secondColor transition duration-300 col-span-2">
          {name}
        </h3>

        {/* Pricing */}
        {hasPriceInfo && (
          <>
            <div className="flex col-span-2 items-center gap-2 text-primeColor font-bold text-lg">
              {salePrice && (
                <>
                  <FaTag className="mr-1" />
                  <span>{salePrice} {t('currency')}</span>
                </>
              )}
              {originalPrice && (
                <span className="line-through text-gray-500 text-sm">
                  {originalPrice} {t('currency')}
                </span>
              )}
            </div>

            {salePercent && (
              <div className="flex gap-2 items-center text-green-600 mb-2">
                <FaPercentage className="inline-block mr-2" /> {t('details.discount')} {salePercent}%
              </div>
            )}
          </>
        )}

        {/* Tests Info */}
        {tests && (
          <div className="flex items-center gap-x-2 bg-gray-100 p-2 rounded-lg text-gray-600 col-span-2">
            <FaVial className="text-lg text-primeColor" />
            <span className="text-sm font-semibold">{t('offer.test')}: {tests}</span>
          </div>
        )}

       {/* Dates */}
{startDate && endDate && (
  <div className={`flex flex-col gap-1 bg-gray-100 p-2 rounded-lg text-gray-600 col-span-2 ${isArabic ? 'text-right' : 'text-left'}`}>
    <div className="flex items-center">
      <FaCalendarAlt className={`mr-2 text-primeColor ${isArabic ? 'ml-2' : 'mr-2'}`} />
      <span className={`flex text-sm font-semibold ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
        {t('offer.start')}: {formattedStartDate}
      </span>
    </div>
    <div className="flex items-center">
      <FaCalendarAlt className={`mr-2 text-primeColor ${isArabic ? 'ml-2' : 'mr-2'}`} />
      <span className="text-sm font-semibold">
        {t('offer.end')}: {formattedEndDate}
      </span>
    </div>
  </div>
)}

      </div>

      {/* Action Buttons always at the bottom */}
      {!isExpired &&(
        <div className="flex gap-4 col-span-2 mt-auto p-4 w-full">
        <Link to="/package/booking" state={packageData} className="flex-1 no-underline bg-secondColor text-white py-2 rounded-lg shadow transition duration-300 hover:bg-primeColor-dark text-center">
          {t('offer.packages.bookNow')}
        </Link>
        <Link to="/package/detail" state={packageData} className="flex-1 bg-primeColor text-white py-2 rounded-lg shadow transition duration-300 hover:bg-secondColor-dark text-center">
          {t('offer.packages.more')}
        </Link>
      </div>
      )}
    </div>
  );
};

export default Offer;
