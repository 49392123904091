import React from 'react';
import { FaTimes } from 'react-icons/fa'; // Import close icon from react-icons

const SendingStatusPopup = ({  error,status, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-8 rounded-lg shadow-2xl text-center relative w-[90%] md:w-[400px]">
      {/* Close Button */}
      <button
        className="absolute top-4 right-4 text-gray-400 hover:text-gray-700 transition-transform transform hover:scale-110"
        onClick={onClose}
      >
        <FaTimes size={22} />
      </button>

      {status === 'sending' && (
        <>
          <h2 className="text-2xl font-semibold mb-6 text-primeColor">جارٍ إرسال البيانات...</h2>
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500"></div>
        </>
      )}
      {status === 'success' && (
        <>
          <h2 className="text-2xl font-semibold mb-4 text-green-600">تم الإرسال بنجاح!</h2>
          <p className="text-gray-700 mb-6">شكراً لك! تم إرسال البيانات بنجاح.</p>
          <button
            className="bg-green-500 text-white py-3 px-6 rounded-full font-medium shadow-lg hover:bg-green-600 transition duration-300 transform hover:scale-105"
            onClick={onClose}
          >
            إغلاق
          </button>
        </>
      )}
      {status === 'failure' && (
        <>
          <h2 className="text-2xl font-semibold mb-4 text-red-600">فشل الإرسال</h2>
          <p className="text-gray-700 mb-6">حدث خطأ أثناء إرسال البيانات. حاول مرة أخرى.</p>
          <p className="text-gray-700 mb-6">{error}</p>
          <button
            className="bg-red-500 text-white py-3 px-6 rounded-full font-medium shadow-lg hover:bg-red-600 transition duration-300 transform hover:scale-105"
            onClick={onClose}
          >
            إغلاق
          </button>
        </>
      )}
    </div>
  </div>
);

export default SendingStatusPopup;
