import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import InputField from './InputField';
import TextArea from './TextArea';
import axios from 'axios';
import ConfirmationPopup from './Confirm';
import SendingStatusPopup from './SendingStatusPopup';
import { useTranslation } from 'react-i18next';
import API_ENDPOINTS from '../../api';

const Booking = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const packageData = location.state || {};

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    notes: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [sendingStatus, setSendingStatus] = useState('');

  // Destructure package and sale/offer-related data
  const {
    name_en,
    name_ar,
    description_en,
    description_ar,
    price,
    tests,
    onSale,
    categoryId,
    priceSale,
    salePercent,
    testsSale,
    descriptionSale_en,
    descriptionSale_ar,
    startDate,
    endDate,
    duration,
    offerDuration,
  } = packageData;

  // Determine if it's a sale or special offer
  const isOffer = offerDuration ? true : false;
  const isPackageSale = onSale === true;

  if (!packageData) {
    return <p>{t('booking.noPackageData')}</p>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleConfirm = async () => {
    setSendingStatus('sending');
    setErrorMessage('');
    setSuccessMessage('');

    const data = {
      ...formData,
      packageTitle: i18n.language === 'ar' ? name_ar : name_en,
      packageDescription: i18n.language === 'ar' ? description_ar : description_en,
      packagePrice: isPackageSale ?priceSale:price,
      salePercent,
      lang:i18n.language, 
    };
    // console.log(data);
    // console.log(salePercent, "salePercent");
    
    try {

      const response = await axios.post(API_ENDPOINTS.MAILS.BOOKING, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Form submitted successfully', response.data);
      setSuccessMessage(t('booking.successMessage'));
      setFormData({
        name: '',
        email: '',
        phone: '',
        notes: '',
      });
      setSendingStatus('success');
    } catch (error) {
      // console.error('Error submitting form', error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(`${t('booking.errorMessage')}: ${error.response.data.message}`);
      } else {
        setErrorMessage(t('booking.generalErrorMessage'));
      }
      setSendingStatus('failure');
    } finally {
      setShowConfirmation(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleClosePopup = () => {
    setSendingStatus('');
  };

  return (
    <div className='min-h-[90vh] p-6 bg-gray-100'>
      <header className="text-center py-4">
        <h1 className="text-4xl font-bold text-primeColor mb-2 after:block after:w-[150px] after:h-1 after:bg-secondColor after:mx-auto after:mt-2">
          {t('booking.header')}
        </h1>
      </header>

      {/* Package, Sale, or Offer Details */}
      <div className={`text-center mb-6 p-4 rounded-lg shadow-md ${isPackageSale ? 'bg-yellow-50 border-yellow-400' : isOffer ? 'bg-green-50 border-green-400' : 'bg-white border-gray-200'} border`}>
        {/* Badge for Sale or Offer */}
        {isPackageSale && (
          <span className="inline-block px-4 py-2 bg-yellow-500 text-white rounded-full mb-4">{t('details.onSale')}</span>
        )}
        {isOffer && (
          <span className="inline-block px-4 py-2 bg-green-500 text-white rounded-full mb-4">{t('details.specialOffer')}</span>
        )}

        {/* Package or Offer Name */}
        <h2 className="text-2xl font-semibold">
          {i18n.language === 'ar' ? name_ar : name_en}
        </h2>

        {/* Pricing Information */}
        {/* <p className="text-lg text-gray-700">
          {t('booking.price')}:
          {isPackageSale ? (
            <span className="text-red-500 font-bold">
              {priceSale || price}{t("currency")}
            </span>
          ) : isOffer ? (
            <span className="text-green-500 font-bold">
              {priceSale}{t("currency")}
            </span>
          ) : (
            <span className="text-primeColor font-semibold">
              {price}{t("currency")}
            </span>
          )}
        </p> */}

          {/* Pricing Information */}
        {(priceSale || price)&&(
          <p className="text-lg text-gray-700">
            {t('booking.price')}:
            <span
              className={`
                font-bold ${
                  isPackageSale ? 'text-red-500' :
                  isOffer ? 'text-green-500' :
                  'text-primeColor font-semibold'
                }
              `}
            >
              {priceSale || price}{t("currency")}
            </span>
          </p>

        )}

        {/* Additional Sale or Offer Information */}
        {isPackageSale && salePercent && (
          <p className="text-sm text-red-500 font-medium">
            {t('details.discount')}: {salePercent}%
          </p>
        )}
        {(testsSale || tests) && (
          <p className="text-sm text-gray-600">
            { t('details.tests')}: {testsSale || tests} فحوصات
          </p>
        )}
      </div>

      {/* Form */}
      <form className="contactForm w-[90%] md:w-[50%] mx-auto p-6 rounded-lg" onSubmit={handleSubmit}>
        <InputField
          label={t('booking.name')}
          type="text"
          name="name"
          placeholder={t('booking.namePlaceholder')}
          value={formData.name}
          onChange={handleChange}
          required={true}
        />

        <InputField
          label={t('booking.email')}
          type="email"
          name="email"
          placeholder={t('booking.emailPlaceholder')}
          value={formData.email}
          onChange={handleChange}
          required
        />

        <InputField
          label={t('booking.phone')}
          type="text"
          name="phone"
          placeholder={t('booking.phonePlaceholder')}
          value={formData.phone}
          onChange={handleChange}
          required
        />

        <TextArea
          label={t('booking.notes')}
          name="notes"
          placeholder={t('booking.notesPlaceholder')}
          value={formData.notes}
          onChange={handleChange}
        />

        <button type="submit" className="bg-blue-500 text-white w-full p-3 rounded hover:bg-blue-600 transition duration-300">
          {t('booking.submit')}
        </button>
      </form>

      {/* Confirmation Popup */}
      {showConfirmation && (
        <ConfirmationPopup 
          onConfirm={handleConfirm} 
          onCancel={() => setShowConfirmation(false)} 
        />
      )}

      {/* Sending Status Popup */}
      {sendingStatus && (
        <SendingStatusPopup status={sendingStatus} onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default Booking;
